<template>
  <div class="im container">

    <Head></Head>
    <main>
      <div class="banner" style="background: #aa55ff;">
        <div class="wrap">
          <div class="txt">
             <h1>在线客服系统</h1>
              <div class="subtitle">全渠道一站式接入，实现一体化管理</div>
              <div>· 网站、微信、小程序、短信、APP等主流渠道，一个平台统一界面，实现统一渠道沟通</div>

              <div>· 多店以客户为中心的视角，提供一致的客服体验</div>

              <div>· 信息互通、数据关联共享</div>
          </div>
          <div class="img">
            <img src="../assets/Class.svg">
          </div>
        </div>
      </div>
      <ContBolck v-for="(item,index) in cont.records" :key="index" :title="item.title" :sub-title="item.desc">
        <div style="text-align:center">
          <img :src="item.src" alt="">
        </div>
      </ContBolck>
    </main>
    <Foot></Foot>
  </div>
</template>

<script>
  import ContBolck from '../components/contBolck'
  import Foot from '../components/foot'
  import Head from '../components/head'
  export default {
    components: {
      ContBolck,
      Foot,
      Head
    },
    data() {
      return {
        cont: {
          records: [{
            title: '全渠道一站式接入， 实现一体化管理',
            desc: '网站、微信、微博、抖音、小程序、短信、APP等主流渠道，一个平台统一界面， 实现统一多渠道沟通，以客户为中心的视角，提供一致的客服体验',
            src: require('../assets/im1.png')
          }, {
            title: '信息互通、数据关联共享',
            desc: 'PC和手机客服端共享访客资料信息、PC 对话记录、手机对话记录、微信对话记录、在线留言记录等信息，实现多渠道下客户服务的连续性。客服可随时查询数据信息,让每个客户的营销状态都一目了然',
            src: require('../assets/im03.jpg')
          }, {
            title: '客服高效接待，客户更满意；智能分配客户，灵活转接',
            desc: '快速识别各个渠道的不同种类客户，按照满意度、转化率、能力值、工作负荷、熟客优先等原则，统一智能分配给对应客服，客服忙时可由机器人接入，保证实时有人接待',
            src: require('../assets/im04.jpg')
          }, {
            title: '可定制接待组件，展现自己的品牌个性',
            desc: '开放式且多元化的访客对话界面， 企业可根据旗下业务应用不同风格灵活打造属于自己接待组件;接待组件可以嵌入企业网站、 手机H5页面、 公众号文章内， 客户点击即刻开始沟通 ',
            src: require('../assets/im05.jpg')
          }, {
            title: '移动客服 - 全渠道接入 ',
            desc: '移动客服支持呼叫中心、 企业网站、 企业移动App.微信、 微博抖音、 小程序等渠道的一站式接入。 7 X24小时办公无需电脑， 可通过手机随时随地与访客沟通交流， 不错过每个潜在访客， 让办公更加灵活 ',
            src: require('../assets/im06.jpg')
          }]
        }
      }
    }
  }
</script>


<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    main {
      flex: 1;

      .banner {

        background: #2979FF;

        .wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 520px;
          line-height: 36px;
          color: #FFF;

          h1 {
            font-size: 36px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 20px;
          }

          .img {
            width: 35%;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: flex-end;

            img {
              width: 100%;
            }
          }

        }
      }
    }
  }
</style>